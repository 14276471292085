/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faPhoneSquareAlt} from "@fortawesome/pro-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Women's Institute"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.p, null, "For further information contact Heather Williams on ", React.createElement(_components.a, {
    href: "tel:+447854742189"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt,
    size: "sm"
  }), " : 07854742189"))), "\n", React.createElement(_components.p, null, React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), " Women's Institute meets on the second Tuesday of every month at 7:30 pm at the Village Hall."), "\n", React.createElement(_components.p, null, "Come and see what we do. You will receive a very warm welcome."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
